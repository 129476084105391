import firebase from 'firebase/app'
import 'firebase/firestore'

import moment from 'moment'
import numeral from 'numeral'
import request from 'request'

const shippingCountryCodes = [
  // { value: 'AT', text: 'Austria' },
  { value: 'BE', text: 'Belgium' },
  // { value: 'BG', text: 'Bulgaria' },
  // { value: 'HR', text: 'Croatia' },
  // { value: 'CY', text: 'Cyprus' },
  // { value: 'CZ', text: 'Czech Republic' },
  // { value: 'DK', text: 'Denmark' },
  // { value: 'EE', text: 'Estonia' },
  // { value: 'FI', text: 'Finland' },
  { value: 'FR', text: 'France' },
  { value: 'DE', text: 'Germany' },
  // { value: 'GR', text: 'Greece' },
  // { value: 'HU', text: 'Hungary' },
  // { value: 'IC', text: 'Iceland' },
  { value: 'IE', text: 'Ireland' },
  // { value: 'IT', text: 'Italy' },
  // { value: 'LV', text: 'Latvia' },
  // { value: 'LT', text: 'Lithuania' },
  // { value: 'LU', text: 'Luxembourg' },
  // { value: 'MT', text: 'Malta' },
  { value: 'NL', text: 'Netherlands' },
  // { value: 'NO', text: 'Norway' },
  // { value: 'PO', text: 'Poland' },
  // { value: 'RO', text: 'Romania' },
  // { value: 'SK', text: 'Slovakia' },
  // { value: 'SI', text: 'Slovenia' },
  // { value: 'ES', text: 'Spain' },
  { value: 'SE', text: 'Sweden' }
]

// Full list used for billing details
const countryCodes = [
  { value: 'AT', text: 'Austria' },
  { value: 'BE', text: 'Belgium' },
  { value: 'BG', text: 'Bulgaria' },
  { value: 'HR', text: 'Croatia' },
  { value: 'CY', text: 'Cyprus' },
  { value: 'CZ', text: 'Czech Republic' },
  { value: 'DK', text: 'Denmark' },
  { value: 'EE', text: 'Estonia' },
  { value: 'FI', text: 'Finland' },
  { value: 'FR', text: 'France' },
  { value: 'DE', text: 'Germany' },
  { value: 'GR', text: 'Greece' },
  { value: 'HU', text: 'Hungary' },
  { value: 'IC', text: 'Iceland' },
  { value: 'IE', text: 'Ireland' },
  { value: 'IT', text: 'Italy' },
  { value: 'LV', text: 'Latvia' },
  { value: 'LT', text: 'Lithuania' },
  { value: 'LU', text: 'Luxembourg' },
  { value: 'MT', text: 'Malta' },
  { value: 'NL', text: 'Netherlands' },
  { value: 'NO', text: 'Norway' },
  { value: 'PO', text: 'Poland' },
  { value: 'RO', text: 'Romania' },
  { value: 'SK', text: 'Slovakia' },
  { value: 'SI', text: 'Slovenia' },
  { value: 'ES', text: 'Spain' },
  { value: 'SE', text: 'Sweden' },
  { value: 'US', text: 'United States' }
]

const usStateCodes = [
  { text: 'Alabama', value: 'AL' },
  { text: 'Alaska', value: 'AK' },
  { text: 'American Samoa', value: 'AS' },
  { text: 'Arizona', value: 'AZ' },
  { text: 'Arkansas', value: 'AR' },
  { text: 'California', value: 'CA' },
  { text: 'Colorado', value: 'CO' },
  { text: 'Connecticut', value: 'CT' },
  { text: 'Delaware', value: 'DE' },
  { text: 'District Of Columbia', value: 'DC' },
  { text: 'Federated States Of Micronesia', value: 'FM' },
  { text: 'Florida', value: 'FL' },
  { text: 'Georgia', value: 'GA' },
  { text: 'Guam', value: 'GU' },
  { text: 'Hawaii', value: 'HI' },
  { text: 'Idaho', value: 'ID' },
  { text: 'Illinois', value: 'IL' },
  { text: 'Indiana', value: 'IN' },
  { text: 'Iowa', value: 'IA' },
  { text: 'Kansas', value: 'KS' },
  { text: 'Kentucky', value: 'KY' },
  { text: 'Louisiana', value: 'LA' },
  { text: 'Maine', value: 'ME' },
  { text: 'Marshall Islands', value: 'MH' },
  { text: 'Maryland', value: 'MD' },
  { text: 'Massachusetts', value: 'MA' },
  { text: 'Michigan', value: 'MI' },
  { text: 'Minnesota', value: 'MN' },
  { text: 'Mississippi', value: 'MS' },
  { text: 'Missouri', value: 'MO' },
  { text: 'Montana', value: 'MT' },
  { text: 'Nebraska', value: 'NE' },
  { text: 'Nevada', value: 'NV' },
  { text: 'New Hampshire', value: 'NH' },
  { text: 'New Jersey', value: 'NJ' },
  { text: 'New Mexico', value: 'NM' },
  { text: 'New York', value: 'NY' },
  { text: 'North Carolina', value: 'NC' },
  { text: 'North Dakota', value: 'ND' },
  { text: 'Northern Mariana Islands', value: 'MP' },
  { text: 'Ohio', value: 'OH' },
  { text: 'Oklahoma', value: 'OK' },
  { text: 'Oregon', value: 'OR' },
  { text: 'Palau', value: 'PW' },
  { text: 'Pennsylvania', value: 'PA' },
  { text: 'Puerto Rico', value: 'PR' },
  { text: 'Rhode Island', value: 'RI' },
  { text: 'South Carolina', value: 'SC' },
  { text: 'South Dakota', value: 'SD' },
  { text: 'Tennessee', value: 'TN' },
  { text: 'Texas', value: 'TX' },
  { text: 'Utah', value: 'UT' },
  { text: 'Vermont', value: 'VT' },
  { text: 'Virgin Islands', value: 'VI' },
  { text: 'Virginia', value: 'VA' },
  { text: 'Washington', value: 'WA' },
  { text: 'West Virginia', value: 'WV' },
  { text: 'Wisconsin', value: 'WI' },
  { text: 'Wyoming', value: 'WY' }
]

export default {
  // Firestore timestamps can be either Timestamp objects or Maps so force to Timestamp for toDate() method
  ensureFirestoreTimestamp(t) {
    if (t.constructor.name !== 'Timestamp') {
      return new firebase.firestore.Timestamp(t.seconds, t.nanoseconds)
    } else {
      return t
    }
  },
  convertDate(time) {
    if (!time) return ''

    time = this.ensureFirestoreTimestamp(time)
    return moment(time.toDate()).format('DD/MM/YYYY')
  },
  momentFromTimestamp(time) {
    return moment(time.toDate())
  },
  vuetifyTimestamp(time) {
    var s = moment(time.toDate()).format('YYYY-MM-DD')
    return s
  },
  timestampFromMoment(moment) {
    return new firebase.firestore.Timestamp.fromMillis(moment.valueOf())
  },
  timestampFromVuetify(time, shift) {
    let t
    if (shift === 'start') {
      t = moment(time, 'YYYY-MM-DD').startOf('day')
    } else if (shift === 'end') {
      t = moment(time, 'YYYY-MM-DD').endOf('day')
    } else {
      t = moment(time, 'YYYY-MM-DD')
    }
    return time ? new firebase.firestore.Timestamp.fromMillis(t.valueOf()) : null
  },
  humanFromVuetify(time) {
    if (!time) return null
    return moment(time, 'YYYY-MM-DD').format('Do MMM')
  },
  millisFromVuetify(time) {
    if (!time) return null
    return moment(time, 'YYYY-MM-DD').valueOf()
  },
  formatDateTime(time) {
    if (!time) return ''

    time = this.ensureFirestoreTimestamp(time)
    return moment(time.toDate()).format('DD MMM YYYY HH:mm')
  },
  xeroDateToMoment(ds) {
    ds = ds.split('(')[1].split('+')[0]
    return moment(parseInt(ds))
  },
  getMilliseconds(time) {
    if (!time) return ''

    time = this.ensureFirestoreTimestamp(time)
    return moment(time.toDate()).valueOf()
  },
  relativeTime(time) {
    if (!time) return ''

    time = this.ensureFirestoreTimestamp(time)
    return moment(time.toDate()).fromNow()
  },
  formatCurrency(currency, val) {
    numeral.locale(currency)
    return numeral(val).format('$0,0.00')
  },
  getVersionByID(versions, id) {
    for (var i = versions.length - 1; i >= 0; i--) {
      if (versions[i].id == id) {
        return versions[i]
      }
    }
  },
  // evaluates YYYY-MM-DD and returns string of past, present or future
  datePPF(day) {
    const today = moment()
    const m = moment(day)
    let result
    if (m.isSame(today, 'day')) {
      result = 'present'
    } else if (m.isBefore(today)) {
      result = 'past'
    } else if (m.isAfter(today)) {
      result = 'future'
    } else {
      console.error('datePPF', day)
    }
    return result
  },
  applyPercentage(value, percentage) {
    return value * (1 - percentage / 100)
  },
  // accepts the response from the data() function not the doc object
  invoiceValInGBP(order) {
    try {
      if (!order.currency) {
        // legacy for FOC orders
        return 0
      } else {
        let val
        if (order.currency != 'GBP' && !order.exchangeRates) {
          val = 0
        } else {
          val = order.currency == 'GBP' ? order.total : order.total / order.exchangeRates.rates[order.currency]
        }
        return val
      }
    } catch (error) {
      console.error('invoiceValInGBP', error, order)
    }
  },

  copyToClipboard(str) {
    const el = document.createElement('textarea')
    el.addEventListener('focusin', e => e.stopPropagation())
    el.value = str
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  },

  groupArrayByKey(xs, key) {
    return xs.reduce(function(rv, x) {
      rv[x[key]] = rv[x[key]] || []
      rv[x[key]].push(x)
      return rv
    }, {})
  },

  isColourDark(color) {
    if (!color) return false

    // Variables for red, green, blue values
    var r, g, b, hsp

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

      r = color[1]
      g = color[2]
      b = color[3]
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +('0x' + color.slice(1, 7).replace(color.length < 5 && /./g, '$&$&'))
      r = color >> 16
      g = (color >> 8) & 255
      b = color & 255
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return false
    } else {
      return true
    }
  },

  isColourRed(color) {
    if (!color) return false

    // Variables for red, green, blue values
    let r, g, b

    if (color.match(/^#/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

      r = color >> 16
      g = (color >> 8) & 255
      b = color & 255

      // console.log(r, g, b)
    }

    // more red than green and more red than blue.
    if (r - g > 50 && r - b > 50) {
      return true
    }
  },

  camelize(str) {
    if (!str) return null
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase()
      })
      .replace(/\s+/g, '')
  },

  getSlackUserName(email) {
    var slackUsernameMap = {
      'anne@plykea.com': '@UCZDZFNHX',
      'kath@plykea.com': '@UJN239HMH',
      'adam@plykea.com': '@U7CS8UFM5',
      'becky@plykea.com': '@UKE1QBMT2',
      'laura@plykea.com': '@UKM11C53J',
      'tara@plykea.com': '@UL47R566T',
      'katie@plykea.com': '@UA0U2P4MQ',
      'tim@plykea.com': '@U7BPNJ2BF'
    }

    return slackUsernameMap[email]
  },

  slackError(step, email, error) {
    request({
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      method: 'POST',
      url: 'https://hooks.slack.com/services/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',
      json: {
        blocks: [
          {
            type: 'context',
            elements: [
              {
                type: 'mrkdwn',
                text: 'Place Order - ' + step
              }
            ]
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: '*Customer Email* ' + email
            }
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: '```' + error + '```'
            }
          }
        ]
      }
    })
  },

  removeEmpty(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') this.removeEmpty(obj[key])
      else if (obj[key] === undefined) delete obj[key]
    })
    return obj
  },

  mmToInch(mm) {
    return parseFloat(mm / 25.4).toFixed(3)
  },

  getCountryFromCode(code) {
    let c = 'Invalid Country Code: ' + code
    for (var i = 0; i < countryCodes.length; i++) {
      if (code == countryCodes[i].value) {
        c = countryCodes[i].text
        break
      }
    }
    return c
  },

  toFixedNumber(num, digits, base) {
    var pow = Math.pow(base || 10, digits)
    return Math.round(num * pow) / pow
  },

  shippingCountryCodes,
  countryCodes,
  usStateCodes
}

<template>
  <v-container fluid fill-height class="align-start pa-0">
    <v-row id="checkout" class="ma-0 fill-height">
      <v-col id="flow" cols="12" md="8">
        <v-stepper v-if="!order && !version.foc" :value="currentStep" class="elevation-0 mb-5">
          <v-stepper-header>
            <v-stepper-step :editable="currentStep == 2" :complete="currentStep > 1" @click="currentStep = 1" step="1"
              >Information
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="currentStep == 3" step="2">Payment </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" :complete="currentStep == 3">Confirmation </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
        <transition name="fade" mode="out-in">
          <component
            :version="version"
            :stripeObjects.sync="stripeObjects"
            :elements="elements"
            :stripe="stripe"
            :form.sync="form"
            :order="order"
            :newOrder="newOrder"
            :foc="version.foc"
            :region="quote.region"
            :allowBankTransfer="version.allowBankTransfer"
            :is="stepComponents[currentStep - 1]"
            :key="currentStep"
            @success="flowSuccess"
            @back="currentStep--"
          ></component>
        </transition>
      </v-col>
      <v-col id="summary" cols="12" md="4">
        <Summary
          :quote="quote"
          :version="version"
          :order="order"
          :balanceDue="balanceDue"
          :newOrder="newOrder"
          :currentStep="currentStep"
        ></Summary>
      </v-col>
    </v-row>

    <v-overlay v-if="submitting" class="text-center" opacity=".95" color="primary">
      <div v-if="!error && !cardErrorMessage" class="text-xs-center">
        <h3>Processing Order - Do Not Leave Or Refresh The Page</h3>
        <v-progress-circular class="mt-4" :size="50" :width="3" color="accent" indeterminate></v-progress-circular>
        <div class="label mt-4">{{ progressText }}</div>
      </div>
      <div v-if="error" class="text-xs-center">
        <h3>There was an error placing your order</h3>
        <p>Your card has <b>NOT</b> been charged.</p>
        <p>
          We have been made aware of the problem and will contact you via email to resolve the issue. Please do not try
          and place your order again.
        </p>
      </div>
      <div v-if="cardErrorMessage" class="text-xs-center">
        <h3>{{ cardErrorMessage }}</h3>
        <v-btn
          class="mt-4"
          color="accent"
          depressed
          @click="
            () => {
              submitting = false
              cardErrorMessage = null
            }
          "
          >Try Again</v-btn
        >
      </div>
    </v-overlay>

    <v-overlay v-if="calculatingSalesTax" class="text-center" opacity=".95" color="primary">
      <div v-if="!salesTaxError">
        <v-progress-circular :size="50" :width="3" color="accent" indeterminate></v-progress-circular>
        <div v-if="calculatingSalesTax" class="body-1 text-center mt-4">
          Calculating Sales Tax
        </div>
      </div>
      <div v-else>
        <p>{{ salesTaxError }}</p>
        <v-btn
          color="accent"
          @click="
            () => {
              salesTaxError = null
              calculatingSalesTax = false
            }
          "
          >Try Again</v-btn
        >
      </div>
    </v-overlay>
  </v-container>
</template>

<script>
import Summary from '@/components/customer/checkout/Summary'

import ContactInformationEU from '@/components/customer/checkout/ContactInformationEU'
import ContactInformationUS from '@/components/customer/checkout/ContactInformationUS'

import PaymentEU from '@/components/customer/checkout/PaymentEU'
import PaymentUS from '@/components/customer/checkout/PaymentUS'

import Confirmation from '@/components/customer/checkout/Confirmation'

export default {
  name: 'Checkout',
  components: {
    ContactInformationEU,
    ContactInformationUS,
    PaymentEU,
    PaymentUS,
    Confirmation,
    Summary
  },
  props: ['quote', 'version', 'order'],
  data() {
    return {
      stripe: null,
      elements: null,
      currentStep: 1,
      submitting: false,
      error: false,
      cardErrorMessage: null,
      progressText: 'Placing Order',
      stepComponents:
        this.quote.region === 'us'
          ? ['ContactInformationUS', 'PaymentUS', 'Confirmation']
          : ['ContactInformationEU', 'PaymentEU', 'Confirmation'],
      form: {
        email: this.quote.customerEmail,
        billingSameAsDelivery: true,
        deliveryCountry: this.quote.region == 'us' ? 'US' : null,
        bankTransfer: 0
      },
      stripeObjects: {
        cardName: null,
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null
      },
      calculatingSalesTax: false,
      salesTaxError: null,
      newOrder: false
    }
  },
  computed: {
    balanceDue() {
      if (!this.order) return null
      const total =
        this.order.region === 'us' && this.version.pricing.salesTax
          ? this.version.pricing.total + this.version.pricing.salesTax.amount_to_collect
          : this.version.pricing.total
      let paymentsTotal = 0
      for (const payment of this.order.payments) {
        paymentsTotal += payment.amount
      }
      return total - paymentsTotal
    }
  },
  created() {
    const pk_live = this.quote.region === 'us' ? 'pk_live_wkqHseRJMGkI2NjoWSHeSulB' : 'pk_live_aewsxnqC09OfkMgkq2rpN5Kw'
    const pk_test = this.quote.region === 'us' ? 'pk_test_YeXX1cZIcegbpp3n4u4oMSff' : 'pk_test_b3KTo1cFHBzSScUAa3zN4VrR'

    var stripe_pk = window.location.hostname == 'mega.plykea.com' ? pk_live : pk_test

    // Ignore error from globally available Stripe lib
    // eslint-disable-next-line
    this.stripe = Stripe(stripe_pk)
    this.elements = this.stripe.elements({
      fonts: [{ cssSrc: 'https://use.typekit.net/uxg0dfx.css' }]
    })

    // If order already exists jump to payment for balance
    if (this.order) {
      this.currentStep = 2
      if (this.order.region == 'us') {
        this.form.deliveryPostcode = this.order.customerContactDetails.deliveryPostcode
        this.form.deliveryState = this.order.customerContactDetails.deliveryState
        this.calculateSalesTax()
      }
    }
  },
  async mounted() {
    this.form.email = this.quote.customerEmail

    // Populate delivery information if previous order exists
    if (this.quote.orders && Object.values(this.quote.orders).length > 0) {
      const orders = Object.values(this.quote.orders)
      const lastOrder = await this.$db
        .collection('orders')
        .doc(orders[orders.length - 1].orderID)
        .get()

      this.$set(this.form, 'firstName', lastOrder.data().customerContactDetails.firstName)
      this.$set(this.form, 'lastName', lastOrder.data().customerContactDetails.lastName)
      this.$set(this.form, 'mobile', lastOrder.data().customerContactDetails.mobile)
      this.$set(this.form, 'deliveryAddressLine1', lastOrder.data().customerContactDetails.deliveryAddressLine1)
      this.$set(this.form, 'deliveryAddressLine2', lastOrder.data().customerContactDetails.deliveryAddressLine2)
      this.$set(this.form, 'deliveryPostcode', lastOrder.data().customerContactDetails.deliveryPostcode)

      if (this.quote.region === 'us') {
        this.$set(this.form, 'deliveryCity', lastOrder.data().customerContactDetails.deliveryCity)
        this.$set(this.form, 'deliveryState', lastOrder.data().customerContactDetails.deliveryState)
        this.$set(this.form, 'phone1', lastOrder.data().customerContactDetails.phone1)
        delete this.form.mobile
      }
    }
  },
  methods: {
    async flowSuccess() {
      if (this.currentStep == 1) {
        if (this.quote.region == 'us') {
          const salesTaxResult = await this.calculateSalesTax()
          if (!salesTaxResult) return
        }

        if (this.version.foc) {
          this.submitting = true
          // customerCheck must be true
          await this.createOrder()
          this.currentStep = 3
          this.submitting = false
        } else {
          this.currentStep++
          this.$vuetify.goTo(0)
        }
      } else {
        this.submitting = true
        // customerCheck must be true
        this.$vuetify.goTo(0)

        const orderToPlace = this.order || (await this.createOrder())
        if (orderToPlace) {
          if (this.form.bankTransfer) {
            this.currentStep = 3
            this.submitting = false
          } else {
            await this.processPayment(orderToPlace)
          }
        }
      }
    },
    async createOrder() {
      // Confirmation component changes text dependent on this var
      this.newOrder = true

      try {
        // Create Order in Firebase
        this.progressText = 'Creating Order'
        const orderTotal =
          this.quote.region == 'us'
            ? this.version.pricing.total + this.version.pricing.salesTax.amount_to_collect
            : this.version.pricing.total

        let orderObj = {
          balanceOutstanding: this.version.pricing.total > 0 && !this.version.foc,
          bankTransfer: this.form.bankTransfer ? true : false,
          allowDeposit: this.version.allowDeposit,
          foc: this.version.foc,
          payments: [],
          numPayments: 0,
          quoteID: this.quote.id,
          region: this.quote.region,
          versionID: this.version.id,
          total: this.$utils.toFixedNumber(orderTotal, 2),
          customerContactDetails: this.form,
          customerEmail: this.quote.customerEmail.toLowerCase(),
          creatorEmail: this.version.creatorEmail,
          currency: this.version.currency,
          shipmentsCreated: false,
          type: 'mega-admin'
        }

        if (this.version.recut) {
          orderObj.recut = {
            cockupID: this.version.cockupID,
            originalVersionID: this.version.recutOriginalVersionID,
            originalOrderID: this.version.recutOriginalOrderID
          }
        }

        if (this.version.exchangeRates) {
          orderObj.exchangeRates = this.version.exchangeRates
        }

        if (this.quote.region === 'us') {
          orderObj.dispatchEta = null
          orderObj.inProduction = null
          orderObj.trackingNumber = null
          orderObj.shipped = false
        }

        const createNewOrderFunc = this.$firebase.functions().httpsCallable('createNewOrder')
        const newOrder = await createNewOrderFunc(orderObj)

        this.$emit('orderCreated', newOrder.data.id)
        return newOrder.data
      } catch (e) {
        console.error(e)
        this.error = true
      }
    },
    async processPayment(order) {
      this.progressText = 'Processing Payment'

      try {
        let paymentAmount
        if (order.payments.length > 0) {
          paymentAmount = Math.round(this.balanceDue * 100)
        } else {
          paymentAmount = this.version.allowDeposit ? Math.round(order.total * 50) : Math.round(order.total * 100)
        }

        // Create Payment Intent
        var getPaymentIntent = this.$firebase.functions().httpsCallable('getPaymentIntent')

        var paymentIntent = await getPaymentIntent({
          amount: paymentAmount,
          currency: this.version.currency,
          description: 'Plykea Order - ' + order.orderNumber,
          metadata: {
            orderNumber: order.orderNumber,
            orderID: order.id
          }
        })

        const orderRef = this.$db.collection('orders').doc(order.id)
        await orderRef.update({
          pendingPaymentIntentID: paymentIntent.data.id
        })

        // Charge the card
        const cardPayment = await this.stripe.handleCardPayment(
          paymentIntent.data.client_secret,
          this.stripeObjects.cardNumber,
          {
            payment_method_data: {
              billing_details: {
                name: this.form.customerName,
                email: this.form.email.toLowerCase()
              }
            }
          }
        )

        if (cardPayment.error) {
          this.cardErrorMessage = cardPayment.error.message

          // log the payment error to slack
          const logPaymentErrorFunc = this.$firebase.functions().httpsCallable('logPaymentError')
          await logPaymentErrorFunc({
            email: order.customerContactDetails.email,
            error: cardPayment.error.message
          })
        } else {
          this.currentStep = 3
          this.submitting = false
        }
      } catch (e) {
        this.$utils.slackError('Error processing card', this.form.email, e)
        this.error = true
      }
    },
    async calculateSalesTax() {
      try {
        this.calculatingSalesTax = true

        const calculateSalesTax = this.$firebase.functions().httpsCallable('calculateSalesTax')
        const salesTax = await calculateSalesTax({
          to_zip: this.form.deliveryPostcode,
          to_state: this.form.deliveryState,
          amount: this.version.pricing.total - this.version.pricing.shipping,
          shipping: this.version.pricing.shipping,
          line_items: [
            {
              id: '1',
              quantity: 1,
              unit_price: this.version.pricing.total - this.version.pricing.shipping,
              discount: 0
            }
          ]
        })

        this.version.pricing.salesTax = salesTax.data

        var doc = this.$db.collection('quotes').doc(this.quote.id)
        await doc.set(this.quote)

        this.calculatingSalesTax = false
        return true
      } catch (error) {
        this.salesTaxError = error.message
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#checkout {
  & *:not(.v-icon) {
    font-family: 'proxima-nova', sans-serif;
    letter-spacing: 0.5px !important;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-variant-ligatures: none;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 15px;
  }

  #flow {
    padding: 50px 100px 25px 100px;
  }

  #summary {
    background: $sand;
    padding: 70px 30px 25px 30px;
  }

  .v-stepper__step {
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }

  ::v-deep.form-group {
    border: solid 1px #e0e0e0;
    padding: 10px 20px 15px;
    border-radius: 5px;
    margin-top: 15px;
  }
}
</style>

const users = [
  {
    email: 'angie@plykea.com',
    name: 'Angie',
    colour: '#000000',
    team: 'sales'
  },
  {
    email: 'katie@plykea.com',
    name: 'Katie',
    colour: '#000000',
    team: 'sales'
  },
  { email: 'combined', name: 'Team', colour: '#000000', team: 'sales' },
  { email: 'anne@plykea.com', name: 'Anne', colour: '#003f5c', team: 'sales' },
  // {
  //   email: 'becky@plykea.com',
  //   name: 'Becky',
  //   colour: '#374c80',
  //   team: 'sales'
  // },
  { email: 'kath@plykea.com', name: 'Kath', colour: '#7a5195', team: 'sales' },
  { email: 'kate@plykea.com', name: 'Kate', colour: '#374c80', team: 'sales' },
  {
    email: 'laura@plykea.com',
    name: 'Laura',
    colour: '#bc5090',
    team: 'sales'
  },
  { email: 'pip@plykea.com', name: 'Pip', colour: '#ef5675', team: 'sales' },
  { email: 'leah@plykea.com', name: 'Leah', colour: '#ff764a', team: 'sales' },
  { email: 'stine@plykea.com', name: 'Stine', colour: '#ffa600', team: 'sales' }
]

export default {
  users
}

<template>
  <div>
    <v-overlay v-if="loading" color="primary" :opacity="0.9" class="text-center"
      ><v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <div v-else>
      <div id="view-sub-nav">
        <v-spacer></v-spacer>
        <v-btn
          class="ml-3"
          :disabled="this.selected.length == 0"
          depressed
          outlined
          color="primary"
          @click="generateSalesTax"
          >Generate Missing Sales Tax Data</v-btn
        >
        <v-btn
          class="ml-3"
          :disabled="this.selected.length == 0"
          depressed
          outlined
          color="primary"
          @click="bulkInvoice"
          >Generate Invoice{{ this.selected.length > 1 ? 's' : '' }}</v-btn
        >
        <v-btn
          class="ml-3"
          :disabled="this.selected.length !== 1"
          depressed
          outlined
          color="primary"
          @click="
            () => {
              addInvoiceDialog = true
              invoice = {}
            }
          "
          >Add Missing Invoice Data</v-btn
        >
      </div>
      <v-data-table v-model="selected" :items="missingInvoice" :headers="headers" show-select>
        <template #item.orderNumber="{item}">
          {{ item.id }}
        </template>
        <template #item.orderNumber="{item}">
          {{ item.data().orderNumber || '-' }}
        </template>
        <template #item.invoiceNumber="{item}">
          {{ item.data().xeroInvoiceNumber || '-' }}
        </template>
        <template #item.orderType="{item}">
          {{ item.data().type }}
        </template>
        <template #item.customerEmail="{item}">
          {{ item.data().customerContactDetails.email }}
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="addInvoiceDialog" width="800"
      ><v-card>
        <v-card-title>Add Invoice Data</v-card-title>
        <v-card-text>
          <v-text-field v-model="invoice.id" label="Xero Invoice ID"></v-text-field>
          <v-text-field v-model="invoice.number" label="Xero Invoice Number"></v-text-field>
          <v-text-field v-model="invoice.url" label="Xero Invoice URL"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="addInvoiceData">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'Invoicing',
  data() {
    return {
      selected: [],
      loading: false,
      missingInvoice: [],
      headers: [
        {
          text: 'ID',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Order #',
          sortable: false,
          value: 'orderNumber'
        },
        {
          text: 'Invoice #',
          sortable: false,
          value: 'invoiceNumber'
        },
        {
          text: 'Type',
          sortable: false,
          value: 'orderType'
        },
        {
          text: 'Customer Email',
          sortable: false,
          value: 'customerEmail'
        }
      ],
      addInvoiceDialog: false,
      invoice: {}
    }
  },
  async created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true

      const orderSnap = await this.$db
        .collection('orders')
        .where('shipped', '==', true)
        .get()

      for (const order of orderSnap.docs) {
        if (order.data().foc) {
          continue
        }

        if (order.data().payments.length > 0) {
          if (order.data().xeroInvoiceNumber) {
            continue
          } else {
            if (order.data().type === 'sample-pack') {
              if (order.data().dispatched) {
                this.missingInvoice.push(order)
                continue
              }
            }

            if (order.data().type === 'mega-admin') {
              if (order.data().region === 'us') {
                if (order.data().shipped) {
                  this.missingInvoice.push(order)
                  continue
                }
              }

              let shipped = true
              const shipmentsSnap = await this.$db
                .collection('shipments')
                .where('orderID', '==', order.id)
                .get()
              for (const shipment of shipmentsSnap.docs) {
                if (shipment.data().shipped === false) shipped = false
              }
              if (shipped) {
                this.missingInvoice.push(order)
                continue
              } else {
                continue
              }
            }
          }
        }
      }
      this.loading = false
    },
    async addInvoiceData() {
      this.addInvoiceDialog = false
      this.loading = true
      await this.selected[0].ref.update({
        xeroInvoiceID: this.invoice.id,
        xeroInvoiceNumber: this.invoice.number,
        xeroInvoiceURL: this.invoice.url
      })
      this.loading = false
    },
    async bulkInvoice() {
      this.loading = true
      try {
        const orderNumbers = []
        for (const order of this.selected) {
          orderNumbers.push(order.id)
        }
        const batchInvoiceFunc = this.$firebase.functions().httpsCallable('manualBatchInvoice')
        await batchInvoiceFunc(orderNumbers)
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    async generateSalesTax() {
      try {
        this.loading = true

        for (const order of this.selected) {
          if (order.data().region === 'us') {
            const quoteSnap = await this.$db
              .collection('quotes')
              .doc(order.data().quoteID)
              .get()

            let quoteData = quoteSnap.data()

            const version = quoteData.versions.find(v => {
              return v.id === order.data().versionID
            })

            const calculateSalesTax = this.$firebase.functions().httpsCallable('calculateSalesTax')
            const salesTax = await calculateSalesTax({
              to_zip: order.data().customerContactDetails.deliveryPostcode,
              to_state: order.data().customerContactDetails.deliveryState,
              amount: version.pricing.total - version.pricing.shipping,
              shipping: version.pricing.shipping,
              line_items: [
                {
                  id: '1',
                  quantity: 1,
                  unit_price: version.pricing.total - version.pricing.shipping,
                  discount: 0
                }
              ]
            })

            version.pricing.salesTax = salesTax.data

            var doc = this.$db.collection('quotes').doc(order.data().quoteID)
            await doc.set(quoteData)
          }
        }

        this.loading = false
        return true
      } catch (error) {
        //this.salesTaxError = error.message
        this.loading = false
        return false
      }
    }
  }
}
</script>

<template>
  <div id="payment">
    <div v-if="allowBankTransfer" class="mb-6">
      <h3>Payment Method</h3>

      <v-btn-toggle v-model="form.bankTransfer" class="d-flex mt-4">
        <v-btn @click="mountStripe">Card</v-btn>
        <v-btn @click="unmountStripe">Bank Transfer</v-btn>
      </v-btn-toggle>
    </div>
    <div v-if="!form.bankTransfer">
      <h3>Card Details</h3>
      <div class="form-group">
        <v-text-field
          v-model="stripeObjects.cardName"
          label="Name on Card"
          v-validate="'required'"
          data-vv-name="Name on Card"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('Name on Card')"
          required
        >
        </v-text-field>
        <v-row wrap>
          <v-col cols="11">
            <div class="stripe-input">
              <div class="fancy-label" :class="{ tiny: cardNumberFocussed }">
                Card Number
              </div>
              <div ref="cardNumber"></div>
            </div>
          </v-col>
          <v-col cols="1" d-flex align-center class="mt-2">
            <transition name="fade">
              <img :src="cardIcons[cardType]" alt="Payment Card Icon" height="20" class="" />
            </transition>
          </v-col>
          <v-col cols="12" class="py-0"
            ><div class="error--text v-messages">
              {{ cardErrors.cardNumber }}
            </div></v-col
          >
        </v-row>
        <v-row wrap>
          <v-col cols="12" md="6">
            <div class="stripe-input">
              <div class="fancy-label" :class="{ tiny: cardExpiryFocussed }">
                Expiration Date (MM/YY)
              </div>
              <div ref="cardExpiry"></div>
            </div>
          </v-col>
          <v-col cols="10" md="5">
            <div class="stripe-input">
              <div class="fancy-label" :class="{ tiny: cardCvcFocussed }">
                Security Code
              </div>
              <div ref="cardCvc"></div>
            </div>
          </v-col>
          <v-col cols="2" md="1" d-flex align-center class="mt-2">
            <transition name="fade">
              <img :src="cardIcons.security" alt="Payment Card Icon" height="20" />
            </transition>
          </v-col>
          <v-col cols="12" md="6" class="py-0"
            ><div class="error--text v-messages">
              {{ cardErrors.cardExpiry }}
            </div></v-col
          >
          <v-col cols="12" md="6" class="py-0"
            ><div class="error--text v-messages">
              {{ cardErrors.cardCvc }}
            </div></v-col
          >
        </v-row>
      </div>
    </div>
    <v-form ref="form" id="order-form">
      <h3 class="mt-4">Billing Address</h3>
      <div class="form-group">
        <v-switch label="Same as delivery" v-model="form.billingSameAsDelivery" hide-details> </v-switch>
        <transition name="fade">
          <div v-if="!form.billingSameAsDelivery">
            <v-text-field
              v-model="form.billingAddressLine1"
              label="Address Line 1"
              v-validate="'required'"
              data-vv-name="Billing Address Line 1"
              data-vv-validate-on="blur"
              :error-messages="errors.collect('Billing Address Line 1')"
              required
            >
            </v-text-field>
            <v-text-field v-model="form.billingAddressLine2" label="Address Line 2"> </v-text-field>
            <v-text-field
              v-model="form.billingPostcode"
              label="Postcode"
              v-validate="'required'"
              data-vv-name="Billing Postcode"
              data-vv-validate-on="blur"
              :error-messages="errors.collect('Billing Postcode')"
              required
            >
            </v-text-field>
            <v-autocomplete
              v-if="region != 'uk'"
              v-model="form.billingCountry"
              :autocomplete="uid(10)"
              :items="$utils.countryCodes"
              label="Country"
              v-validate="'required'"
              data-vv-name="Billing Country"
              data-vv-validate-on="blur"
              :error-messages="errors.collect('Billing Country')"
            ></v-autocomplete>
          </div>
        </transition>
      </div>
      <h3 class="mt-4">Sign Off</h3>
      <div class="form-group">
        <p class="py-4 ml-8">I confirm the following highlighted features of my order. I also acknowledge that any changes required after placing my order may incur additional costs and/or delay my order.
        </br>
        <small>(This is just an overview and I have thoroughly checked the full details of my order.)</small>
        </p>
        <!-- visual sign off -->
        <v-checkbox
          class="sign-off"
          label="Confirm Visual is correct (including handle positions)"
          v-model="form.visualApproval"
          color="accent"
          v-validate="'required:true'"
          data-vv-name="visual sign off"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('visual sign off')"
        ></v-checkbox>
        <!-- fronts sign off -->
        <v-checkbox 
        class="sign-off"
        v-if="version.fronts && version.fronts.length > 0"
          v-for="material in frontSignOff"
          :name="material.id"
          :key="material.id"
          :label="
            `Fronts, Cover Panels and/or Plinths in ${material.colourCode ? material.colourCode : '' } ${material.label} ${material.thickness}mm ${
              material.unfinished ? '(unfinished)' : ''
            }`
          "
          v-model="form.frontsApproval"
          color="accent"
          v-validate="'required:true'"
          data-vv-name="fronts sign off"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('fronts sign off')"
        ></v-checkbox>
        <!-- handles sign off -->
        <v-checkbox
          class="sign-off"
          v-if="version.handles && version.handles.length > 0"
          :label="`${handlesSignOff} Handles`"
          v-model="form.handlesApproval"
          color="accent"
          v-validate="'required:true'"
          data-vv-name="handles sign off"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('handles sign off')"
        ></v-checkbox>
        <!-- spacers sign off -->
        <v-checkbox
          class="sign-off"
          v-if="version.spacerPanels && version.spacerPanels.length > 0"
          :label="`${spacerSignOff} Spacer Panels`"
          v-model="form.spacersApproval"
          color="accent"
          v-validate="'required:true'"
          data-vv-name="spacers sign off"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('spacers sign off')"
        ></v-checkbox>
        <!-- worktops sign off -->
        <v-checkbox
          class="sign-off"
          v-if="version.worktops && version.worktops.length > 0"
          :label="`${worktopSignOff.sentence} Worktops ${worktopSignOff.features}`"
          v-model="form.worktopsApproval"
          color="accent"
          v-validate="'required:true'"
          data-vv-name="worktops sign off"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('worktops sign off')"
        ></v-checkbox>
        <!-- urtil sign off -->
        <v-checkbox
          class="sign-off"
          v-if="version.urtils && version.urtils.length > 0"
          :label="`${urtilSignOff.sentence} Urtils ${urtilSignOff.features}`"
          v-model="form.urtilsApproval"
          color="accent"
          v-validate="'required:true'"
          data-vv-name="urtil sign off"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('urtil sign off')"
        ></v-checkbox>
        <!-- pantry sign off -->
        <v-checkbox
          class="sign-off"
          v-if="version.pantries && version.pantries.length > 0"
          :label="`${pantrySignOff.sentence} Pantries ${pantrySignOff.features}`"
          v-model="form.pantriesApproval"
          color="accent"
          v-validate="'required:true'"
          data-vv-name="pantry sign off"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('pantry sign off')"
        ></v-checkbox>
        <!-- additional items sign off -->
        <v-checkbox
          class="sign-off"
          v-if="version.additionalItems && version.additionalItems.length > 0"
          label="Confirm Additional Items"
          v-model="form.additionalItemsApproval"
          color="accent"
          v-validate="'required:true'"
          data-vv-name="additional item sign off"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('additional item sign off')"
        ></v-checkbox>
      </div>
    </v-form>
    <div class="mt-5" style="display: flex; justify-content: space-between">
      <v-btn color="primary" text class="mx-0 px-0" @click="$emit('back')"
        ><v-icon>mdi-chevron-left</v-icon>Back to information</v-btn
      >
      <v-btn color="accent" depressed class="mx-0" @click="submit">{{ form.bankTransfer ? 'Continue' : 'Pay' }}</v-btn>
    </div>
  </div>
</template>

<script>
import productData from '@/js/productData'

import uid from 'uid'
import monodefault from 'payment-icons/min/mono/default.svg'
import security from 'payment-icons/min/mono/security-code.svg'
import visa from 'payment-icons/min/single/visa.svg'
import mastercard from 'payment-icons/min/flat/mastercard.svg'
import amex from 'payment-icons/min/flat/amex.svg'
import discover from 'payment-icons/min/flat/discover.svg'
import diners from 'payment-icons/min/flat/diners.svg'
import jcb from 'payment-icons/min/flat/jcb.svg'
import unionpay from 'payment-icons/min/flat/unionpay.svg'

export default {
  name: 'PaymentEU',
  props: ['form', 'elements', 'stripeObjects', 'allowBankTransfer', 'region', 'version'],
  data() {
    return {
      productData: productData,
      cardErrors: {},
      cardIcons: {
        visa: visa,
        mastercard: mastercard,
        amex: amex,
        discover: discover,
        diners: diners,
        jcb: jcb,
        unionpay: unionpay,
        unknown: monodefault,
        security: security
      },
      uid: uid,
      stripeElementBaseStyle: {
        base: {
          fontSize: '16px',
          fontFamily: 'proxima-nova, sans-serif',
          fontSmoothing: 'antialiased',
          letterSpacing: '0.5px'
        }
      },
      cardNumberFocussed: false,
      cardExpiryFocussed: false,
      cardCvcFocussed: false,
      cardType: 'unknown'
    }
  },
  mounted() {
    this.mountStripe()
  },
  beforeDestroy() {
    if (!this.form.bankTransfer) {
      this.unmountStripe()
    }
  },
  computed: {
    cardComplete() {
      return (
        Object.keys(this.cardErrors).length == 0 &&
        this.stripeObjects.cardNumber._complete &&
        this.stripeObjects.cardExpiry._complete &&
        this.stripeObjects.cardCvc._complete
      )
    },
    frontSignOff() {
      let materialRequired = this.version.materials.filter(material => {
        if (
          this.version.pricing?.fronts[material.id] &&
          this.version.pricing.fronts[material.id].total > 0 &&
          material.thickness == 18
        ) {
          return material
        }
      })
      return materialRequired
    },
    handlesSignOff() {
      let handlesList = []

      this.version.handles.forEach(handle => {
        let baseName = handle.label.includes(' - ') ? handle.label.split(' - ')[1] : handle.label
        let handleName = baseName.includes(' / ') ? handle.label.split(' - ')[1].split(' / ')[0]: baseName
        let handleMaterial = baseName.includes(' / ') ? handle.label.split(' - ')[1].split(' / ')[1]: ''
        let handleFullName = handleName == 'None' ? 'None Handle' : handleMaterial + ' ' + handleName

        if(!handlesList.includes(handleFullName)) handlesList.push(handleFullName)
      })

      let handlesSignOff = this.formSentenceFromArray(handlesList)
      return handlesSignOff
    },
    spacerSignOff() {
      let spacerMaterials = []
      this.version.spacerPanels.forEach(spacer => {
        let materialName = spacer.material.replace(spacer.material[0], spacer.material[0].toUpperCase())
        if (!spacerMaterials.includes(materialName)) {
          spacerMaterials.push(materialName)
        }
      })
      let spacerSentence = this.formSentenceFromArray(spacerMaterials)
      return spacerSentence
    },
    worktopSignOff() {
      let worktopNameList = []
      let features = {
        dogleg: false,
        upstand: false
      }

      if (this.version.worktops.length > 0) {
        let masterWorktops

        if (this.region == 'uk') {
          masterWorktops = this.$store.state.worktops.filter(o => {
            return o.region === 'uk'
          })
        } else if (this.region == 'us') {
          masterWorktops = this.$store.state.worktops.filter(o => {
            return o.region === 'us'
          })
        }

        this.version.worktops.forEach(worktop => {
          let worktopMaterial = masterWorktops.find(o => {
            return o.value === worktop.material
          })

          let masterMaterial = this.$store.state.materials.find(o => {
            return worktopMaterial.material === o.value
          })

          let colourName = masterMaterial?.colours.find(o => {
            return o.value === worktop.colourCode
          })

          let worktopMaterialName = worktopMaterial.material.replace(
            worktopMaterial.material[0],
            worktopMaterial.material[0].toUpperCase()
          )
          let worktopSentence = colourName.text + ' ' + worktopMaterialName

          if (!worktopNameList.includes(worktopSentence)) worktopNameList.push(worktopSentence)
          if (worktop.sections.find(section => section.dogleg)) features.dogleg = true
          if (worktop.sections.find(section => section.customCoreThickness)) features.upstand = true
        })
      }
      let worktopSentence = this.formSentenceFromArray(worktopNameList)

      let featuresSentence = ''
      if (features.dogleg && !features.upstand) {
        featuresSentence = ' (includes L-shaped cutout)'
      } else if (!features.dogleg && features.upstand) {
        featuresSentence = ' (includes upstand)'
      } else if (features.dogleg && features.upstand) {
        featuresSentence = ' (includes L-shaped cutout & upstand)'
      }

      let worktopDetails = {
        sentence: worktopSentence,
        features: featuresSentence
      }
      return worktopDetails
    },
    urtilSignOff() {
      let urtilDoorList = []
      let urtilCarcassList = []

      this.version.urtils.forEach(urtil => {
        let urtilType = productData.getUrtilType(urtil.type).label
        let carcassName = this.getMaterialName(urtil.carcass).split('/')[0]

        if (!urtilDoorList.includes(urtilType)) urtilDoorList.push(urtilType)
        if (!urtilCarcassList.includes(carcassName)) urtilCarcassList.push(carcassName)
      })

      let doorSentence = ' (consists of ' + this.formSentenceFromArray(urtilDoorList) + ' Urtils)'
      let carcassSentence = this.formSentenceFromArray(urtilCarcassList)

      let urtilSignOff = {
        sentence: carcassSentence,
        features: doorSentence
      }

      return urtilSignOff
    },
    pantrySignOff() {
      let includesBase = false
      let pantryCarcassList = []

      this.version.pantries.forEach(pantry => {
        let carcassName = this.getMaterialName(pantry.carcass).split('/')[0]

        if (pantry.carcass != pantry.base && pantry.base) includesBase = true
        if (!pantryCarcassList.includes(carcassName)) pantryCarcassList.push(carcassName)
      })

      let baseSentence = includesBase ? ' (includes laminate base)' : ''
      let carcassSentence = this.formSentenceFromArray(pantryCarcassList)

      let pantrySignOff = {
        sentence: carcassSentence,
        features: baseSentence
      }

      return pantrySignOff
    }
  },
  methods: {
    async mountStripe() {
      await this.$nextTick()

      this.setupStripeElement('cardNumber', 'cardNumberFocussed')
      this.setupStripeElement('cardExpiry', 'cardExpiryFocussed')
      this.setupStripeElement('cardCvc', 'cardCvcFocussed')
    },
    unmountStripe() {
      this.unmountStripeElement('cardNumber')
      this.unmountStripeElement('cardExpiry')
      this.unmountStripeElement('cardCvc')
    },
    setupStripeElement(el, focus) {
      this.stripeObjects[el] = this.elements.create(el, {
        style: this.stripeElementBaseStyle,
        placeholder: ''
      })
      this.stripeObjects[el].mount(this.$refs[el])

      this.stripeObjects[el].addEventListener('change', event => {
        if (event.brand) this.cardType = event.brand

        if (event.error) {
          if (!this.cardErrors[event.elementType]) {
            this.$set(this.cardErrors, event.elementType, event.error.message)
          } else {
            this.cardErrors[event.elementType] = event.error.message
          }
        } else if (this.cardErrors[event.elementType]) {
          this.$delete(this.cardErrors, event.elementType)
        }
      })
      this.stripeObjects[el].addEventListener('focus', () => {
        this[focus] = true
      })
      this.stripeObjects[el].addEventListener('blur', () => {
        this[focus] = !this.stripeObjects[el]._empty
      })
    },
    unmountStripeElement(el) {
      this.stripeObjects[el].destroy()
      this.stripeObjects[el].removeEventListener('change')
      this.stripeObjects[el].removeEventListener('focus')
      this.stripeObjects[el].removeEventListener('blur')
    },
    submit() {
      if (this.region === 'uk' && !this.form.billingSameAsDelivery) {
        this.form.billingCountry = 'GB'
      }
      this.$validator.validateAll().then(result => {
        if (result && (this.cardComplete || this.form.bankTransfer)) {
          this.$emit('success', this.form)
        }
      })
    },
    formSentenceFromArray(array) {
      let connectGrammar = array.length == 2 ? ' and ' : ', and '

      let formedSentence =
        array.length == 1 ? array[0] : array.slice(0, array.length - 1).join(', ') + connectGrammar + array.slice(-1)

      return formedSentence
    },
    getMaterialName(code) {
      for (var i = 0; i < this.version.materials.length; i++) {
        if (code == this.version.materials[i].id) {
          var m = this.version.materials[i]
          return m.colourCode ? m.label + ' / ' + m.colourCode : m.label
        }
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
#payment {
  .stripe-input {
    position: relative;
    margin-top: 16px;
    border-bottom: solid 1px #949494;
    .fancy-label {
      font-size: 16px;
      position: absolute;
      top: 5px;
      opacity: 0.7;
      transition: transform 0.25s, opacity 0.5s;
      transform-origin: top left;
      &.tiny {
        opacity: 1;
        transform: scale(0.7) translate(0, -25px);
      }
    }
  }
  .sign-off {
    .v-input__slot {
      align-items: flex-start;
    }
  }
  .StripeElement {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .v-input--switch {
    margin: 10px 0;
  }
  .v-input--checkbox {
    margin-top: 5;
  }
  .v-btn-toggle {
    button {
      flex: 1;
    }
  }
}
</style>
